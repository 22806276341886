<template>
    <div>
        <!--zh-cn-->
        <div v-if="lang == 'zh_CN'">
            <section class="wrapper">
                <h1 id="tos">什么是明文私钥？</h1>
                <p>我们常说，<strong>你对钱包中资金的控制取决于相应私钥的所有权和控制权</strong>。在区块链交易中, 私钥用于生成支付货币所必须的签名，以证明资金的所有权。私钥必须始终保持机密，因为一旦泄露给第三方，相当于该私钥保护下的资产也拱手相让了。</p>
                <p>私钥实际上并不是存储在网络中，而是由用户生成并存储在一个文件或者简单的数据库中，称为钱包。存储在用户钱包中的私钥完全独立，可由用户的钱包软件生成并管理，无需区块链或者网络连接。</p>
                <p>私钥的样式为 64 位 16 进制的哈希值字符串, 例如:</p>
                <blockquote>
                    <p>56f759ece75f0ab1b783893cbe390288978d4d4ff24dd233245b4285fcc31cf6</p>
                </blockquote>
                <p><strong>PS：</strong>用户可以使用明文私钥导入 Infinity Wallet 来修改对应钱包的密码。</p>
            </section>
        </div>

        <!--en-->
        <div v-else-if="lang == 'en'">
            <section class="wrapper">
                <h1 id="tos">What is a plaintext private key? </h1>
                <p>We often say that <strong>your control of the funds in your wallet depends on the ownership and control of the corresponding private key</strong>. In blockchain transactions, the private key is used to generate the signature necessary for payment currency to prove the ownership of funds. The private key must be kept secret at all times, because once it is leaked to a third party, the assets under the protection of the private key are also handed over. </p>
                <p>The private key is not actually stored in the network, but is generated by the user and stored in a file or a simple database called a wallet. The private key stored in the user's wallet is completely independent and can be generated and managed by the user's wallet software without the need for blockchain or network connection. </p>
                <p>The style of the private key is a 64-bit hexadecimal hash value string, for example:</p>
                <blockquote>
                    <p>56f759ece75f0ab1b783893cbe390288978d4d4ff24dd233245b4285fcc31cf6</p>
                </blockquote>
                <p><strong>PS:</strong> Users can use the plaintext private key to import Infinity Wallet to modify the password of the corresponding wallet. </p>
            </section>
        </div>

        <!--zh_TW-->
        <div v-else>
            <section class="wrapper">
                <h1 id="tos">什麼是明文私鑰？ </h1>
                <p>我們常說，<strong>你對錢包中資金的控制取決於相應私鑰的所有權和控制權</strong>。在區塊鏈交易中, 私鑰用於生成支付貨幣所必須的簽名，以證明資金的所有權。私鑰必須始終保持機密，因為一旦洩露給第三方，相當於該私鑰保護下的資產也拱手相讓了。 </p>
                <p>私鑰實際上並不是存儲在網絡中，而是由用戶生成並存儲在一個文件或者簡單的數據庫中，稱為錢包。存儲在用戶錢包中的私鑰完全獨立，可由用戶的錢包軟件生成並管理，無需區塊鍊或者網絡連接。 </p>
                <p>私鑰的樣式為 64 位 16 進制的哈希值字符串, 例如:</p>
                <blockquote>
                    <p>56f759ece75f0ab1b783893cbe390288978d4d4ff24dd233245b4285fcc31cf6</p>
                </blockquote>
                <p><strong>PS：</strong>用戶可以使用明文私鑰導入 Infinity Wallet 來修改對應錢包的密碼。 </p>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Mnemonic',
        components: {},
        props: {},
        data() {
            return {
                lang: this.$route.params.lang || 'en'
            }
        },
        computed: {},
        methods: {},
        mounted() {

        },
    }
</script>

<style>

</style>
